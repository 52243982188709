<template>
  <div class="text-left">
    <button @click="toggleSessionModal" data-cy="create-session">
      <span>Add a session</span>
    </button>

    <SimpleModal
      class="text-center"
      v-if="toggleModal"
      @close="toggleModal = false"
    >
      <div class="flex flex-wrap mb-14 justify-start">
        <div class="w-1/2">
          <p class="text-xs text-black">Device</p>
          <select
            v-model="device"
            placeholder="12345"
            data-cy="select-session-device"
          >
            <option
              v-for="device in this.$store.state.devices"
              v-bind:key="device.deviceId"
              v-bind:value="device"
              >{{ device.deviceType }} - {{ device.deviceId }}</option
            >
          </select>
        </div>
        <div class="w-1/2">
          <BaseInput
            class="text-theme-darkest mr-4"
            label="Brushing Duration"
            v-model="brushingDuration"
            type="text"
          />
        </div>
        <div class="w-1/2">
          <BaseInput
            class="text-theme-darkest mr-4"
            label="Pressure Duration"
            v-model="pressureDuration"
            type="text"
          />
        </div>
        <div class="w-1/2">
          <p class="text-xs text-black">Brushing Mode</p>
          <select v-model="primaryBrushingMode">
            <option
              v-bind:key="type.name"
              v-for="type in $store.getters.deviceBrushingModeTypes"
              v-bind:value="type.name"
              >{{ type.name }}</option
            >
          </select>
        </div>
        <div class="w-1/2">
          <p class="text-xs text-black">Start Time</p>
          <div
            class="flex justify-start mb-4 p-3 pl-6 w-full"
            style="border-bottom: 1px solid #949494"
          >
            <datetime
              type="datetime"
              v-model="sessionStartTime"
              placeholder="Select date"
              value-zone="UTC"
              zone="local"
              :phrases="{ ok: 'Continue', cancel: 'Exit' }"
              :hour-step="1"
              :minute-step="1"
              :week-start="7"
              use12-hour
              auto
            ></datetime>
          </div>
        </div>
        <div class="w-1/2">
          <button @click="toggleZonedBrushFields">{{ !zonedBrushFields? "Show 6-Zone Coverage" : "Hide 6-Zone Coverage" }}</button>
        </div>
        <template v-if="zonedBrushFields">
          <div class="w-1/2">
            <BaseInput
              class="text-theme-darkest mr-4"
              label="Bottom Left (Zoned Brush Time)"
              v-model="bottomLeft"
              type="text"
              placeholder="defaults to 0 if unspecified"
            />
          </div>
          <div class="w-1/2">
            <BaseInput
              class="text-theme-darkest mr-4"
              label="Bottom Right (Zoned Brush Time)"
              v-model="bottomRight"
              type="text"
              placeholder="defaults to 0 if unspecified"
            />
          </div>
          <div class="w-1/2">
            <BaseInput
              class="text-theme-darkest mr-4"
              label="Bottom Center (Zoned Brush Time)"
              v-model="bottomCenter"
              type="text"
              placeholder="defaults to 0 if unspecified"
            />
          </div>
          <div class="w-1/2">
            <BaseInput
              class="text-theme-darkest mr-4"
              label="Top Left (Zoned Brush Time)"
              v-model="topLeft"
              type="text"
              placeholder="defaults to 0 if unspecified"
            />
          </div>
          <div class="w-1/2">
            <BaseInput
              class="text-theme-darkest mr-4"
              label="Top Right (Zoned Brush Time)"
              v-model="topRight"
              type="text"
              placeholder="defaults to 0 if unspecified"
            />
          </div>
          <div class="w-1/2">
            <BaseInput
              class="text-theme-darkest mr-4"
              label="Top Center (Zoned Brush Time)"
              v-model="topCenter"
              type="text"
              placeholder="defaults to 0 if unspecified"
            />
          </div>
          <div class="w-1/2">
            <BaseInput
              class="text-theme-darkest mr-4"
              label="None (Zoned Brush Time)"
              v-model="none"
              type="text"
              placeholder="defaults to 0 if unspecified"
            />
          </div>
        </template>
      </div>
      <button
        :disabled="loading"
        @click="addSession"
        data-cy="submit-create-session"
      >
        <span v-if="loading">{{ loadingMessage }}</span>
        <span v-else>Create a session</span>
      </button>
      <p class="error" v-if="formError">{{ formError }}</p>
    </SimpleModal>
  </div>
</template>

<script>
import { uuidv4 } from '@/util/uuid';
import SimpleModal from '@/components/UI/SimpleModal.vue';
import { Datetime } from 'vue-datetime';

export default {
  data () {
    return {
      sessionStartTime: new Date().toISOString(),
      toggleModal: false,
      brushingDuration: 120,
      device: this.$store.getters.devices.length
        ? this.$store.getters.devices[0]
        : { deviceId: '12345', deviceType: 'SEAGATE' },
      pressureDuration: 0,
      primaryBrushingMode: 'DAILY_CLEAN',
      zonedBrushFields: false,
      topLeft: 21,
      topCenter: 14,
      topRight: 21,
      bottomLeft: 21,
      bottomCenter: 14,
      bottomRight: 21,
      none: 0
    };
  },
  components: {
    SimpleModal,
    datetime: Datetime
  },
  created () {
    this.$store.dispatch('updateOralBBrushingModeTypes');
  },
  computed: {
    loading () {
      return this.$store.getters.loading;
    },
    loadingMessage () {
      return this.$store.getters.loadingStatusMessage;
    },
    zonedBrushTime () {
      return this.zonedBrushFields
        ? '{ "topLeft":' + (this.topLeft || 0) +
      ', "topRight":' + (this.topRight || 0) +
      ', "topCenter":' + (this.topCenter || 0) +
      ', "bottomLeft":' + (this.bottomLeft || 0) +
      ', "bottomRight":' + (this.bottomRight || 0) +
      ', "bottomCenter":' + (this.bottomCenter || 0) +
      ', "none":' + this.none +
      '}'

        : null;
    }
  },
  methods: {
    toggleSessionModal () {
      this.toggleModal = !this.toggleModal;
    },
    toggleZonedBrushFields () {
      this.zonedBrushFields = !this.zonedBrushFields;
    },
    resetForm () {
      this.sessionStartTime = new Date().toISOString();
      this.brushingDuration = 120;
      this.pressureDuration = 0;
      this.primaryBrushingMode = 'DAILY_CLEAN';
      this.topLeft = 21;
      this.topCenter = 14;
      this.topRight = 21;
      this.bottomLeft = 21;
      this.bottomCenter = 14;
      this.bottomRight = 21;
      this.none = 0;
      this.zonedBrushFields = false;
      this.device = this.$store.getters.devices.length
        ? this.$store.getters.devices[0]
        : { deviceId: '12345', deviceType: 'SEAGATE' };
    },
    createSessionObj () {
      return {
        brushingDuration: this.brushingDuration,
        pressureDuration: this.pressureDuration,
        primaryBrushingMode: this.primaryBrushingMode,
        sessionStartTime: this.sessionStartTime.split('.')[0] + '+00:00',
        deviceId: this.device.deviceId,
        deviceType: this.device.deviceType,
        sessionId: uuidv4(),
        batteryLevel: randomNumber(),
        brushScore: randomNumber(),
        cleanTongue: 'DISABLED',
        coveragePercentage: 0.73,
        flossTeeth: 'DISABLED',
        sessionType: 'GATEWAY',
        gumBleeding: 'DISABLED',
        handleSessionId: randomNumber(),
        gatewayModel: 178,
        brushModel: 113,
        onEventCount: randomNumber(),
        pressureDistribution: '5555555555555555555555555555555555555559555555',
        pressureEventCount: randomNumber(),
        rinseMouth: 'DISABLED',
        zonedBrushTime: this.zonedBrushTime
      };
    },

    async addSession () {
      this.$store
        .dispatch('createSessionMutation', this.createSessionObj())
        .then((res) => {
          if (res.data) {
            this.$store.dispatch('getSessionsQuery');
            this.toggleModal = false;
            this.formError = '';
            this.resetForm();
          } else {
            this.formError = res.errors[0].message;
          }
        })
        .catch(() => {
          this.formError = 'Sorry - There was an error. Please try again.';
        });
    }
  }
};

const randomNumber = () => Math.floor(Math.random() * 10);
</script>
