<template>
  <div class="inline">
    <a class="cursor-pointer" @click="toggleModal">
      Edit Columns
    </a>
    <SimpleModal v-if="setColumnsModal" @close="setColumnsModal = false">
      <div class="text-center">
        <p class="mb-4">Set Columns to Show</p>
        <div class="text-left grid grid-cols-2 gap-4">
          <span v-for="column in brandColumns" v-bind:key="column">
            <input class="mr-2" type="checkbox" :id="column.label" :value="column" v-model="columnsToShow">
            <label class="cursor-pointer" :for="column.label">{{column.label}}</label>
          </span>
        </div>
        <button
          class="block m-auto text-center"
          @click="setSessionColumnPreferences"
        >
          Set Columns
        </button>
      </div>
    </SimpleModal>
  </div>
</template>

<script>
import SimpleModal from '@/components/UI/SimpleModal.vue';

export default {
  data () {
    return {
      setColumnsModal: false,
      columnsToShow: []
    };
  },
  props: {
    brandColumns: Array,
    columnPreferences: Array,
    localStorageName: String
  },
  components: {
    SimpleModal
  },
  created () {
    this.columnsToShow = this.columnPreferences;
  },
  methods: {
    toggleModal () {
      this.setColumnsModal = !this.setColumnsModal;
    },
    setSessionColumnPreferences () {
      this.setColumnsModal = false;
      const columns = this.columnsToShow.map(column => column.field);
      localStorage.setItem(this.localStorageName, JSON.stringify(columns));
      this.$emit('refresh');
    }
  }
};
</script>
