<template>
  <div class="p-8 pt-20">
    <h1 class="font-bold uppercase mb-4 text-2xl">Sessions</h1>
    <div class="flex justify-start">
      <CreateSession />
      <CreateBatchSessions class="ml-6"/>
    </div>
    <SessionsTable />
  </div>
</template>

<script>
import CreateSession from '@/components/forms/CreateSession.vue';
import CreateBatchSessions from '@/components/forms/CreateBatchSessions.vue';
import SessionsTable from '@/components/sessions/SessionsTable.vue';

export default {
  components: {
    CreateSession,
    CreateBatchSessions,
    SessionsTable
  }
};
</script>
