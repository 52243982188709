<template>
  <div class="text-left">
    <button @click="toggleSessionModal" data-cy="create-session">
      <span>Add a session</span>
    </button>

    <SimpleModal
      class="text-center"
      v-if="toggleModal"
      @close="toggleModal = false"
    >
      <div class="flex flex-wrap mb-14 justify-start">
        <div class="w-1/2">
          <p class="text-xs text-black">Device</p>
          <select v-model="deviceId" placeholder="12345">
            <option
              v-for="device in this.$store.state.devices"
              v-bind:key="device.deviceId"
              v-bind:value="device.deviceId"
              >{{ device.deviceType }} - {{ device.deviceId }}</option
            >
          </select>
        </div>
        <div class="w-1/2">
          <p class="text-xs text-black">Device Type</p>
          <select v-model="deviceType" data-cy="dropdown">
            <option
              v-bind:key="type.name"
              v-for="type in $store.getters.deviceTypes"
              v-bind:value="type.name"
              >{{ type.name }}</option
            >
          </select>
        </div>
        <div class="w-1/2">
          <p class="text-xs text-black">cartridgeChange</p>
          <select v-model="cartridgeChange" data-cy="dropdown">
            <option
              v-bind:key="type.name"
              v-for="type in $store.getters.deviceCartridgeChangeTypes"
              v-bind:value="type.name"
              >{{ type.name }}</option
            >
          </select>
        </div>
        <div class="w-1/2">
          <BaseInput
            class="text-theme-darkest mr-4"
            label="cartridgeUse"
            v-model="cartridgeUse"
            type="text"
          />
        </div>

        <div class="w-1/2">
          <p class="text-xs text-black">diagnosticResult</p>
          <select v-model="diagnosticResult" data-cy="dropdown">
            <option
              v-bind:key="type.name"
              v-for="type in $store.getters.deviceDiagnosticResultTypes"
              v-bind:value="type.name"
              >{{ type.name }}</option
            >
          </select>
        </div>
        <div class="w-1/2">
          <p class="text-xs text-black">handleMode</p>
          <select v-model="handleMode" data-cy="dropdown">
            <option
              v-bind:key="type.name"
              v-for="type in $store.getters.deviceHandleModeTypes"
              v-bind:value="type.name"
              >{{ type.name }}</option
            >
          </select>
        </div>
        <div class="w-1/2">
          <BaseInput
            class="text-theme-darkest mr-4"
            label="percentageOverThreshold"
            v-model="percentageOverThreshold"
            type="text"
          />
        </div>
        <div class="w-1/2">
          <BaseInput
            class="text-theme-darkest mr-4"
            label="sessionDuration"
            v-model="sessionDuration"
            type="text"
          />
        </div>
        <div class="w-1/2">
          <BaseInput
            class="text-theme-darkest mr-4"
            label="sessionStrokeCount"
            v-model="sessionStrokeCount"
            type="text"
          />
        </div>
        <div class="w-1/2">
          <BaseInput
            class="text-theme-darkest mr-4"
            label="threshold1"
            v-model="threshold1"
            type="text"
          />
        </div>
        <div class="w-1/2">
          <BaseInput
            class="text-theme-darkest mr-4"
            label="threshold2"
            v-model="threshold2"
            type="text"
          />
        </div>
        <div class="w-1/2">
          <p class="text-xs text-black">Session Start Time</p>
          <div
            class="flex justify-start mb-4 p-3 pl-6 w-full"
            style="border-bottom: 1px solid #949494"
          >
            <datetime
              type="datetime"
              v-model="sessionStartTime"
              placeholder="Select date"
              value-zone="UTC"
              zone="local"
              :phrases="{ ok: 'Continue', cancel: 'Exit' }"
              :hour-step="1"
              :minute-step="1"
              :week-start="7"
              use12-hour
              auto
            ></datetime>
          </div>
        </div>
      </div>

      <button
        :disabled="loading"
        @click="addSession"
        data-cy="submit-create-session"
      >
        <span v-if="loading">{{ loadingMessage }}</span>
        <span v-else>Create a session</span>
      </button>
      <p class="error" v-if="formError">{{ formError }}</p>
    </SimpleModal>
  </div>
</template>

<script>
import { uuidv4 } from '@/util/uuid';
import SimpleModal from '@/components/UI/SimpleModal.vue';
import { Datetime } from 'vue-datetime';

export default {
  data () {
    return {
      sessionStartTime: new Date().toISOString(),
      toggleModal: false,
      deviceId: this.$store.getters.devices.length
        ? this.$store.getters.devices[0].deviceId
        : '1234',
      deviceType: 'HG2',
      cartridgeChange: 'NO',
      cartridgeUse: this.$store.getters.sessions.length + 1,
      diagnosticResult: 'USER_MODE_1',
      handleMode: 'USER_MODE_2',
      percentageOverThreshold: 10.0,
      sessionDuration: 150,
      sessionStrokeCount: 40,
      threshold1: 8000,
      threshold2: 11000
    };
  },
  components: {
    SimpleModal,
    datetime: Datetime
  },
  created () {
    this.$store.dispatch('updateDeviceTypes');
    this.$store.dispatch('updateGilletteCartridgeChangeTypes');
    this.$store.dispatch('updateGilletteDiagnosticResultTypes');
    this.$store.dispatch('updateGilletteHandleMode');
  },
  computed: {
    loading () {
      return this.$store.getters.loading;
    },
    loadingMessage () {
      return this.$store.getters.loadingStatusMessage;
    }
  },
  methods: {
    toggleSessionModal () {
      this.toggleModal = !this.toggleModal;
    },
    resetForm () {
      this.deviceId = this.$store.state.devices.length
        ? this.$store.state.devices[0].deviceId
        : '12345';
      this.deviceType = 'HG2';
      this.cartridgeChange = 'NO';
      this.cartridgeUse = this.$store.state.sessions.length + 2;
      this.diagnosticResult = 'USER_MODE_2';
      this.handleMode = 'USER_MODE_2';
      this.percentageOverThreshold = 10.0;
      this.sessionDuration = 150;
      this.sessionStartTime = new Date().toISOString();
      this.sessionStrokeCount = 40;
      this.threshold1 = 8000;
      this.threshold2 = 11000;
    },
    createSessionObj () {
      return {
        session: {
          consumerId: this.$store.state.user.username,
          deviceId: this.deviceId,
          deviceType: this.deviceType,
          sessionId: uuidv4(),
          cartridgeChange: this.cartridgeChange,
          cartridgeUse: this.cartridgeUse,
          diagnosticResult: this.diagnosticResult,
          handleMode: this.handleMode,
          modelVersion: 'None',
          percentageOverThreshold: this.percentageOverThreshold,
          sessionDuration: this.sessionDuration,
          sessionStartTime: this.sessionStartTime.split('.')[0] + '+00:00',
          sessionStrokeCount: this.sessionStrokeCount,
          threshold1: this.threshold1,
          threshold2: this.threshold2
        }
      };
    },

    async addSession () {
      this.$store
        .dispatch('createSessionMutation', this.createSessionObj())
        .then((res) => {
          if (res.data) {
            this.$store.dispatch('getSessionsQuery');
            this.toggleModal = false;
            this.formError = '';
            this.resetForm();
          } else {
            this.formError = res.errors[0].message;
          }
        })
        .catch(() => {
          this.formError = 'Sorry - There was an error. Please try again.';
        });
    }
  }
};
</script>
