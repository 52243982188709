var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.$store.state.sessionsError.length)?_c('div',[_c('p',{staticClass:"text-right"},[_vm._v(" Total Sessions: "+_vm._s(_vm.$store.state.sessions.length)+" | Updated at: "+_vm._s(new Date().toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true }))+" | "),_c('EditColumns',{attrs:{"brandColumns":this.config.columns,"columnPreferences":this.columns,"localStorageName":"COLUMN_PREFERENCES_SESSIONS"},on:{"refresh":function($event){return _vm.getColumns()}}})],1),_c('vue-good-table',{attrs:{"title":"Sessions Table","columns":this.columns,"rows":_vm.$store.state.sessions,"pagination-options":{
        enabled: true,
        mode: 'pages',
        perPage: 100,
        position: 'top',
        perPageDropdown: [10, 50, 100, 250, 500, 1000, 10000],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        allLabel: 'All'
      },"search-options":{
        enabled: true,
        initialSortBy: { field: 'sessionStartTime', type: 'desc' }
      },"line-numbers":true,"select-options":{
        enabled: true,
        selectOnCheckboxOnly: true,
        clearSelectionText: 'Clear',
      }},on:{"on-cell-click":_vm.onCellClick,"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'deviceId')?_c('a',{attrs:{"href":"#","data-cy":"device-detail-link"}},[_vm._v(_vm._s(props.row.deviceId))]):(props.column.type === 'id')?_c('div',{staticClass:"min-w-220"},[_vm._v(" "+_vm._s(props.row[props.column.field])+" ")]):(props.column.type === 'pressureDistribution')?_c('div',{staticClass:"pressure-distribution"},[_vm._v(" "+_vm._s(props.row[props.column.field])+" ")]):(props.column.type === 'number')?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(props.row[props.column.field])+" ")]):(props.column.field === 'delete')?_c('div',[_c('DeleteSession',{attrs:{"session":props.row}})],1):(props.column.field === 'zonedBrushTime' && props.row.zonedBrushTime)?_c('div',[_c('vue-json-pretty',{attrs:{"data":JSON.parse(props.row.zonedBrushTime),"deep":1,"showLength":"","showLine":"","showDoubleQuotes":"","highlightMouseoverNode":""}})],1):(props.column.type === 'AWSJSON')?_c('div',[_c('vue-json-pretty',{attrs:{"data":JSON.parse(props.row[props.column.field]),"deep":1,"showLength":"","showLine":"","showDoubleQuotes":"","highlightMouseoverNode":""}})],1):(props.column.type === 'AWSDateTime')?_c('TwoDates',{attrs:{"date":props.row[props.column.field]}}):_vm._e()]}}],null,false,376631094)},[_c('div',{staticClass:"batch-delete inline-block",attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('DeleteBatchSessions',{attrs:{"sessions":this.selectedSessions}})],1),_c('div',{staticClass:"emptystate",attrs:{"slot":"emptystate","data-cy":"empty-state"},slot:"emptystate"},[_c('EmptyTable',{attrs:{"loading":_vm.$store.state.loading,"type":"Sessions"}})],1)])],1):_vm._e(),_c('TableErrors',{attrs:{"errors":_vm.$store.state.sessionsError}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }