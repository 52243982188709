<template>
  <div class="text-left">
    <button @click="toggleSessionModal" data-cy="toggle-batch-session">
      <span>Add batch sessions</span>
    </button>

    <SimpleModal
      class="text-center"
      v-if="toggleModal"
      @close="toggleModal = false"
    >
      <div class="flex flex-wrap mb-14 justify-start">
        <div class="w-1/2">
          <p class="text-xs text-black">Device</p>
          <select
            v-model="device"
            placeholder="12345"
            data-cy="select-session-device"
          >
            <option
              v-for="device in devices"
              v-bind:key="device.deviceId"
              v-bind:value="device"
              >{{ device.deviceType }} - {{ device.deviceId }}</option
            >
          </select>
        </div>
        <div class="w-1/2">
          <BaseInput
            class="text-theme-darkest mr-4"
            label="Number of Sessions"
            v-model="numberOfSessions"
            type="text"
          />
        </div>
      </div>
      <p class="error" v-if="formError">{{ formError }}</p>
      <button
        :disabled="loading || formError !==''"
        @click="addBatchSessions"
        data-cy="submit-create-batch-session"
      >
        <span v-if="loading">{{ loadingMessage }}</span>
        <span v-else>Create Batch Sessions</span>
      </button>
    </SimpleModal>
  </div>
</template>

<script>
import { uuidv4 } from '@/util/uuid';
import SimpleModal from '@/components/UI/SimpleModal.vue';
import { mapState } from 'vuex';

export default {
  data () {
    return {
      toggleModal: false,
      formError: '',
      numberOfSessions: 1,
      device: this.$store.getters.devices.length &&
        this.$store.getters.devices[0]
    };
  },
  components: {
    SimpleModal
  },
  computed: {
    ...mapState({
      loading: state => state.loading,
      loadingMessage: state => state.loadingStatus,
      devices: state => state.devices
    })
  },
  created () {
    this.$store.dispatch('updateAllDeviceTypes');
  },
  watch: {
    numberOfSessions: function (val) {
      if (val > 0 && val <= 28) {
        this.formError = '';
      } else {
        this.formError = 'Number of sessions must be a number between 1 and 28. This is equivalent of two weeks worth of data.';
      }
    }
  },
  methods: {
    toggleSessionModal () {
      this.toggleModal = !this.toggleModal;
    },
    addBatchSessions () {
      this.toggleModal = false;
      for (var i = 0; i < this.numberOfSessions; i++) { this.addSession(); }
    },
    async addSession () {
      const sessionObj = this.createSessionObj();
      this.$store
        .dispatch('createSessionMutation', sessionObj)
        .then((res) => {
          if (res.data) {
            this.$store.dispatch('getSessionsQuery');
            this.formError = '';
          } else {
            this.formError = res.errors[0].message;
          }
        });
    },
    createSessionObj () {
      const brand = this.$store.state.brand;
      const device = this.device ? this.device : { deviceId: '12345', deviceType: this.$store.getters.deviceTypes[0].name };

      if (brand.includes('Oral-B')) {
        return this.createOralBSessionObj(device);
      } else if (brand.includes('Gillette')) {
        return this.createGilletteSessionObj(device);
      } else if (brand.includes('Labs')) {
        return this.createLabsSessionObj(device);
      } else if (brand.includes('Braun')) {
        return this.createBraunSessionObj(device);
      } else if (brand.includes('Opte')) {
        return this.createOpteSessionObj(device);
      }
    },
    createGilletteSessionObj (device) {
      return {
        session: {
          consumerId: this.$store.state.user.username,
          deviceId: device.deviceId,
          deviceType: device.deviceType,
          sessionId: uuidv4(),
          cartridgeChange: 'NO',
          cartridgeUse: this.$store.state.sessions.length + 2,
          diagnosticResult: 'USER_MODE_2',
          handleMode: 'USER_MODE_2',
          modelVersion: 'None',
          percentageOverThreshold: 10.0,
          sessionDuration: this.randomNumber(140, 30),
          sessionStartTime: this.randomDate(new Date(2019, 0, 1), new Date()),
          sessionStrokeCount: this.randomNumber(40, 10),
          threshold1: 8000,
          threshold2: 11000
        }
      };
    },
    createLabsSessionObj (device) {
      const randomDate = this.randomDate(new Date(2019, 0, 1), new Date());

      return {
        input: {
          sessionId: uuidv4(),
          deviceId: device.deviceId,
          deviceType: device.deviceType,
          receivedTime: randomDate,
          sessionStartTime: randomDate,
          thingName: device.deviceType + '_' + device.deviceId,
          message: '{ "name" : { "S" : "batch>session" } }'
        }
      };
    },
    createOpteSessionObj (device) {
      return {
        session: {
          deviceId: device.deviceId,
          deviceType: device.deviceType,
          consumerId: uuidv4(),
          sessionId: uuidv4(),
          abTimeoutGrace: this.randomNumber(1, 10),
          abTimeoutIdle: this.randomNumber(1, 10),
          abTimeoutShutdown: this.randomNumber(1, 10),
          abTimeoutSilence: this.randomNumber(1, 10),
          abandonLevel0: 0,
          abandonLevel1: 0,
          abandonLevel2: 0,
          abandonLevel3: 0,
          appOffsetX: 2,
          appOffsetY: -3,
          baseBootcount: 10,
          baseFirmwareBuildOptions: '0x001C1812',
          baseFirmwareFlag: 'EVT',
          baseFirmwareVersion: '0x05020300',
          baseHardwareModel: 'GEN2A',
          baseHardwareVersion: '0x000000B2',
          baseUptime: 111000,
          cameraOffsetHorz: -3,
          cameraOffsetVert: 7,
          cameraOn: 1,
          cameraPerformance: 100,
          cameraRotation: 3,
          cameraRotationalAngle: 0.2618,
          cameraX: this.randomNumber(1, 10),
          cameraY: this.randomNumber(1, 10),
          cartAbsent: 0,
          cartChange: 0,
          cartDropsPrint: 0,
          cartDropsPurge: 0,
          cartTally: 2,
          cartTimeOnSkin: 300,
          cleaning: 0,
          cradleEvents: 3,
          cradleIn: 1,
          cradleOut: 1,
          displayInversionState: 'NORMAL',
          dropsPerClick: 346,
          heaterTimeout: 0.65,
          idleTimeout: 120,
          imageHeight: 14,
          imageWidth: 126,
          lidClose: 1,
          lidEvents: 3,
          lidOpen: 1,
          nozzlePrefireDelta: 1.2,
          nozzlePrefireWidth: 0.7,
          nozzlePulseDelta: 1.2,
          nozzlePulseWidth: 1.7,
          puckAbsent: 0,
          puckEvents: 0,
          puckPresent: 0,
          regVoltageMax: 0.0,
          regVoltageMin: 0.0,
          rollerWindowHeight: 120,
          rollerWindowOffsetX: 59,
          rollerWindowOffsetY: 16,
          rollerWindowWidth: 8,
          rssi: -70,
          sessionDuration: 180,
          sessionStartTime: this.randomDate(new Date(2019, 0, 1), new Date()),
          shade: 1,
          skinImageHeight: 18,
          skinRowStart: 1,
          tempCutoffHigh: 45,
          tempCutoffLow: 0,
          tempShutdownHigh: 60,
          tempShutdownLow: -20,
          thermalEvent: 1,
          tipAbsent: 0,
          tipEvents: 0,
          tipPresent: 0,
          wandAbsent: 0,
          wandBootcount: 0,
          wandEvents: 0,
          wandFirmwareBuildDate: '2019-06-24T19:41:03+00:00',
          wandFirmwareBuildOptions: '0x001C1812',
          wandFirmwareFlag: 'EVT',
          wandFirmwareVersion: '0x05020300',
          wandHardwareModel: 'GEN2A',
          wandHardwareVersion: '0x000000B2',
          wandPresent: 0,
          wandUptime: 0
        }
      };
    },
    createBraunSessionObj (device) {
      const randomDate = this.randomDate(new Date(2019, 0, 1), new Date());

      return {
        input: {
          deviceId: device.deviceId,
          sessionId: uuidv4(),
          sessionStartTime: randomDate,
          receivedTime: randomDate,
          sessionStatus: 'DONE',
          sessionType: 'PLAN',
          traceId: uuidv4(),
          deviceType: device.deviceType,
          client: 'DEVELOPER_DASHBOARD',
          clientVersion: null,
          containedIn: null,
          coveragePercentage: 56,
          hairColor: null,
          hairDensity: null,
          sessionDuration: 34,
          treatment: 1,
          userEvaluation: null, // json
          thingName: device.deviceType.toLowerCase() + '_' + device.deviceId
        }
      };
    },
    createOralBSessionObj (device) {
      return {
        brushingDuration: this.randomNumber(200, 30),
        pressureDuration: 0,
        primaryBrushingMode: 'DAILY_CLEAN',
        sessionStartTime: this.randomDate(new Date(2019, 0, 1), new Date()),
        consumerId: this.$store.state.user.username,
        deviceId: device.deviceId,
        deviceType: device.deviceType,
        sessionId: uuidv4(),
        batteryLevel: this.randomNumber(1, 10),
        brushScore: this.randomNumber(1, 10),
        cleanTongue: 'DISABLED',
        coveragePercentage: 0.73,
        flossTeeth: 'DISABLED',
        sessionType: 'GATEWAY',
        gumBleeding: 'DISABLED',
        handleSessionId: this.randomNumber(1, 10),
        gatewayModel: 178,
        brushModel: 113,
        onEventCount: this.randomNumber(1, 10),
        pressureDistribution: '5555555555555555555555555555555555555559555555',
        pressureEventCount: this.randomNumber(1, 10),
        rinseMouth: 'DISABLED',
        zonedBrushTime: this.zonedBrushTime(Math.floor(Math.random() * 2))
      };
    },
    zonedBrushTime (showZonedBrushFields) {
      return showZonedBrushFields
        ? '{ "topLeft":' + 21 +
      ', "topRight":' + 21 +
      ', "topCenter":' + 14 +
      ', "bottomLeft":' + 21 +
      ', "bottomRight":' + 21 +
      ', "bottomCenter":' + 14 +
      ', "none":' + 0 +
      '}'
        : null;
    },
    randomDate (start, end) {
      return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())).toISOString().split('.')[0] + '+00:00';
    },
    randomNumber (start, end) {
      return Math.floor(Math.random() * end) + start;
    }
  }
};
</script>
