<template>
  <div class="text-left">
    <button @click="toggleSessionModal" data-cy="create-session">
      <span>Add a session</span>
    </button>

    <SimpleModal
      class="text-center"
      v-if="toggleModal"
      @close="toggleModal = false"
    >
      <div class="flex flex-wrap mb-14 justify-start">
        <div class="w-1/2">
          <p class="text-xs text-black">Device (Required)</p>
          <select
            v-model="deviceId"
            placeholder="12345"
            data-cy="select-session-device"
          >
            <option
              v-for="device in devices"
              v-bind:key="device.deviceId"
              v-bind:value="device.deviceId"
              >{{ device.deviceType }} - {{ device.deviceId }}</option
            >
          </select>
        </div>
        <div class="w-1/2">
          <p class="text-xs text-black">Session Status (Required)</p>
          <select v-model="sessionStatus">
            <option
              v-bind:key="status.name"
              v-for="status in braunSessionStatuses"
              v-bind:value="status.name"
              >{{ status.name }}</option
            >
          </select>
        </div>
        <div class="w-1/2">
          <p class="text-xs text-black">Session Type (Required)</p>
          <select v-model="sessionType">
            <option
              v-bind:key="type.name"
              v-for="type in braunSessionTypes"
              v-bind:value="type.name"
              >{{ type.name }}</option
            >
          </select>
        </div>
        <div class="w-1/2">
          <p class="text-xs text-black pb-7px">Start Time (Required)</p>
          <div
            class="flex justify-start mb-4 p-3 pl-6 w-full"
            style="border-bottom: 1px solid #949494"
          >
            <datetime
              type="datetime"
              v-model="sessionStartTime"
              placeholder="Select date"
              value-zone="UTC"
              zone="local"
              :phrases="{ ok: 'Continue', cancel: 'Exit' }"
              :hour-step="1"
              :minute-step="1"
              :week-start="7"
              use12-hour
              auto
            ></datetime>
          </div>
        </div>
        <div class="w-1/2">
          <p class="text-xs text-black">Client</p>
          <select v-model="client">
            <option
              v-bind:key="client.name"
              v-for="client in braunClients"
              v-bind:value="client.name"
              >{{ client.name }}</option
            >
          </select>
        </div>
        <div class="w-1/2">
          <BaseInput
            class="text-theme-darkest mr-4"
            label="Client Version"
            placeholder="Client Version"
            v-model="clientVersion"
            type="text"
          />
        </div>
        <div class="w-1/2">
          <BaseInput
            class="text-theme-darkest mr-4"
            label="Contained In"
            placeholder="Contained In"
            v-model="containedIn"
            type="text"
          />
        </div>
        <div class="w-1/2">
          <BaseInput
            class="text-theme-darkest mr-4"
            label="Coverage Percentage"
            placeholder="Coverage Percentage"
            v-model="coveragePercentage"
            type="number"
          />
        </div>
        <div class="w-1/2">
          <BaseInput
            class="text-theme-darkest mr-4"
            label="Hair Color"
            placeholder="Hair Color"
            v-model="hairColor"
            type="number"
          />
        </div>
        <div class="w-1/2">
          <BaseInput
            class="text-theme-darkest mr-4"
            label="Hair Density"
            placeholder="Hair Density"
            v-model="hairDensity"
            type="number"
          />
        </div>
        <div class="w-1/2">
          <BaseInput
            class="text-theme-darkest mr-4"
            label="Session Duration"
            placeholder="Session Duration"
            v-model="sessionDuration"
            type="number"
          />
        </div>
        <div class="w-1/2">
          <BaseInput
            class="text-theme-darkest mr-4"
            label="Treatment"
            placeholder="Treatment"
            v-model="treatment"
            type="number"
          />
        </div>
        <div class="w-1/2">
          <BaseInput
            class="text-theme-darkest mr-4"
            label="User Evaluation"
            placeholder="JSON"
            v-model="userEvaluation"
            type="text"
          />
        </div>
      </div>

      <button
        :disabled="loading"
        @click="addSession"
        data-cy="submit-create-session"
      >
        <span v-if="loading">{{ loadingMessage }}</span>
        <span v-else>Create a session</span>
      </button>
      <p class="error" v-if="formError">{{ formError }}</p>
    </SimpleModal>
  </div>
</template>

<script>
import { uuidv4 } from '@/util/uuid';
import SimpleModal from '@/components/UI/SimpleModal.vue';
import { Datetime } from 'vue-datetime';
import { mapState } from 'vuex';

export default {
  data () {
    return {
      toggleModal: false,
      sessionStartTime: new Date().toISOString(),
      deviceId: this.$store.getters.devices.length
        ? this.$store.getters.devices[0].deviceId
        : '12345',
      sessionType: 'PLAN',
      sessionStatus: 'DONE',
      client: 'DEVELOPER_DASHBOARD',
      clientVersion: null,
      containedIn: null,
      coveragePercentage: null,
      hairColor: null,
      hairDensity: null,
      sessionDuration: null,
      treatment: null,
      userEvaluation: null
    };
  },
  components: {
    SimpleModal,
    datetime: Datetime
  },
  created () {
    this.$store.dispatch('updateBraunSessionEnums');
  },
  computed: {
    ...mapState({
      loading: state => state.loading,
      loadingMessage: state => state.loadingStatusMessage,
      devices: state => state.devices,
      braunSessionStatuses: state => state.braunSessionStatus,
      braunSessionTypes: state => state.braunSessionType,
      braunClients: state => state.braunClient
    })
  },
  methods: {
    toggleSessionModal () {
      this.toggleModal = !this.toggleModal;
    },

    resetForm () {
      this.sessionStartTime = new Date().toISOString();
      this.deviceId = this.$store.state.devices.length
        ? this.$store.state.devices[0].deviceId
        : '12345';
      this.sessionType = 'PLAN';
      this.sessionStatus = 'DONE';
      this.client = 'DEV_DASHBOARD';
      this.clientVersion = null;
      this.containedIn = null;
      this.coveragePercentage = null;
      this.hairColor = null;
      this.hairDensity = null;
      this.sessionDuration = null;
      this.treatment = null;
      this.userEvaluation = null;
    },
    setDeviceType () {
      return this.$store.state.devices.length
        ? this.$store.state.devices.filter(device => device.deviceId === this.deviceId)[0].deviceType
        : 'VICTORIA';
    },
    setThingName (device, id) {
      return device.toLowerCase() + '_' + id;
    },
    createSessionObj () {
      return {
        input: {
          // required fields
          deviceId: this.deviceId,
          sessionId: uuidv4(),
          sessionStartTime: this.sessionStartTime.split('.')[0] + '+00:00',
          sessionStatus: this.sessionStatus,
          sessionType: this.sessionType,
          // not required for CreateSessionInput but required in Session type
          traceId: uuidv4(),
          // non-required fields
          deviceType: this.setDeviceType(),
          client: this.client,
          clientVersion: this.clientVersion,
          containedIn: this.containedIn,
          coveragePercentage: this.coveragePercentage,
          hairColor: this.hairColor,
          hairDensity: this.hairDensity,
          sessionDuration: this.sessionDuration,
          treatment: this.treatment,
          userEvaluation: this.userEvaluation, // json
          receivedTime: new Date().toISOString().split('.')[0] + '+00:00',
          thingName: this.setThingName(this.setDeviceType(), this.deviceId)
        }
      };
    },

    async addSession () {
      this.$store
        .dispatch('createSessionMutation', this.createSessionObj())
        .then((res) => {
          if (res.data) {
            this.$store.dispatch('getSessionsQuery');
            this.toggleModal = false;
            this.formError = '';
            this.resetForm();
          } else {
            this.formError = res.errors[0].message;
          }
        })
        .catch(() => {
          this.formError = 'Sorry - There was an error. Please try again.';
        });
    }
  }
};
</script>
