<template>
  <div>
    <div v-if="!$store.state.sessionsError.length">
      <p class="text-right">
        Total Sessions: {{ $store.state.sessions.length }} | Updated at:
        {{
          new Date().toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true
          })
        }}
        | <EditColumns
            :brandColumns="this.config.columns"
            :columnPreferences="this.columns"
            localStorageName="COLUMN_PREFERENCES_SESSIONS"
            @refresh="getColumns()"
            />
      </p>
      <vue-good-table
        title="Sessions Table"
        :columns="this.columns"
        :rows="$store.state.sessions"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          perPage: 100,
          position: 'top',
          perPageDropdown: [10, 50, 100, 250, 500, 1000, 10000],
          dropdownAllowAll: false,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page',
          allLabel: 'All'
        }"
        :search-options="{
          enabled: true,
          initialSortBy: { field: 'sessionStartTime', type: 'desc' }
        }"
        :line-numbers="true"
        @on-cell-click="onCellClick"
        @on-selected-rows-change="selectionChanged"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          clearSelectionText: 'Clear',
        }"
      >
        <div slot="selected-row-actions" class="batch-delete inline-block">
          <DeleteBatchSessions :sessions="this.selectedSessions" />
        </div>
        <div slot="emptystate" class="emptystate" data-cy="empty-state">
          <EmptyTable :loading="$store.state.loading" type="Sessions" />
        </div>
        <template slot="table-row" slot-scope="props">
          <a
            href="#"
            v-if="props.column.field === 'deviceId'"
            data-cy="device-detail-link"
            >{{ props.row.deviceId }}</a
          >
          <div v-else-if="props.column.type === 'id'" class="min-w-220">
              {{props.row[props.column.field]}}
          </div>
          <div v-else-if="props.column.type === 'pressureDistribution'" class="pressure-distribution">
              {{props.row[props.column.field]}}
          </div>
          <div v-else-if="props.column.type === 'number'" class="text-center">
              {{props.row[props.column.field]}}
          </div>
          <div v-else-if="props.column.field === 'delete'">
            <DeleteSession :session="props.row" />
          </div>
          <div v-else-if="props.column.field === 'zonedBrushTime' && props.row.zonedBrushTime">
            <vue-json-pretty
              :data="JSON.parse(props.row.zonedBrushTime)"
              :deep="1"
              showLength
              showLine
              showDoubleQuotes
              highlightMouseoverNode
            />
          </div>
           <div v-else-if="props.column.type === 'AWSJSON'">
            <vue-json-pretty
              :data="JSON.parse(props.row[props.column.field])"
              :deep="1"
              showLength
              showLine
              showDoubleQuotes
              highlightMouseoverNode
            />
          </div>
          <TwoDates
            v-else-if="props.column.type === 'AWSDateTime'"
            :date="props.row[props.column.field]"
          />
        </template>
      </vue-good-table>
    </div>
    <TableErrors v-bind:errors="$store.state.sessionsError" />
  </div>
</template>

<script>
import TwoDates from '@/components/UI/TwoDates';
import brandConfig from '@/util/brandConfigs';
import currentBrand from '@/util/getCurrentBrand';
import TableErrors from '@/components/UI/TableErrors.vue';
import EmptyTable from '@/components/UI/EmptyTable.vue';
import DeleteSession from '@/components/sessions/DeleteSession.vue';
import DeleteBatchSessions from '@/components/sessions/DeleteBatchSessions.vue';
import EditColumns from '@/components/UI/EditColumns.vue';

export default {
  data () {
    return {
      config: brandConfig[currentBrand()].sessions,
      columns: [],
      pageTokens: [],
      currentPage: 1,
      FORWARD: 'forward',
      BACKWARD: 'backward',
      limit: 100,
      options: [10, 50, 100, 200, 500],
      selectedSessions: []
    };
  },
  components: {
    TableErrors,
    TwoDates,
    DeleteSession,
    DeleteBatchSessions,
    EditColumns,
    EmptyTable
  },
  created () {
    this.getColumns();
  },
  methods: {
    paginate (direction) {
      if (direction === this.BACKWARD) {
        this.currentPage -= 1;
        this.refresh();
      } else if (direction === this.FORWARD) {
        this.currentPage += 1;
        this.refresh();
      }
    },
    addToPages (newToken) {
      const tokenAlreadyExists = this.pageTokens.find(
        token => token === newToken
      );
      if (tokenAlreadyExists || newToken === '') {
        console.log('End of dataset');
      } else {
        this.pageTokens.push(newToken);
      }
    },
    onCellClick: function (params) {
      if (params.column.field === 'deviceId' && this.ownsDevice(params.row.deviceId)) {
        const { row: { deviceId } } = params;

        this.$store.dispatch('getSelectedDeviceQuery', {
          deviceId: params.row.deviceId
        })
          .then((res) => {
            if (res.errors) {
              alert(res.errors[0].message);
            } else {
              this.$router.push({
                name: 'deviceDetail',
                params: { deviceId },
                query: { prevPage: '/devices' }
              });
            }
          });
      }
    },
    ownsDevice (id) {
      const device = this.$store.state.devices.find(device => device.deviceId === id);
      return device ? true : alert('You no longer own this device');
    },
    selectionChanged (params) {
      this.selectedSessions = params.selectedRows.map(session => session.sessionId);
    },
    getColumns () {
      let columnPreferences = localStorage.getItem('COLUMN_PREFERENCES_SESSIONS');
      if (!columnPreferences) {
        this.columns = this.config.columns;
      }

      columnPreferences = JSON.parse(columnPreferences);
      const columnsToShow = [];
      this.config.columns.map(column => {
        const showColumn = columnPreferences.filter(field => field === column.field);
        if (showColumn.length) {
          columnsToShow.push(column);
        }
      });
      this.columns = columnsToShow;
    }
  },
  watch: {
    limit: function () {
      this.currentPage = 1;
      this.pageTokens = [];
      this.refresh();
    }
  }
};
</script>

<style lang="scss">
  .pressure-distribution {
      width: 250px !important;
      line-break: anywhere;
      text-align: left;
  }

  .vgt-selection-info-row.clearfix{
    position: relative;
  }

  .batch-delete {
    position: absolute;
    left: 250px;
}
</style>
