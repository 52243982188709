<template>
  <div>
    <CreateOralBSession
      v-if="$store.state.brand.includes('Oral-B')"
    />
    <CreateGilletteSession
      v-else-if="$store.state.brand.includes('Gillette')"
    />
    <CreateLabsSession
      v-else-if="$store.state.brand.includes('Labs')"
    />
    <CreateBraunSession
      v-else-if="$store.state.brand.includes('Braun')"
    />
    <div v-else class="text-left">
      <button :disabled="loading" @click="addSession" data-cy="create-session">
        <span v-if="loading">{{ loadingMessage }}</span>
        <span v-else>Add a session</span>
      </button>
      <p class="error" v-if="formError">{{ formError }}</p>
    </div>
  </div>
</template>

<script>
import { uuidv4 } from '@/util/uuid';
import CreateOralBSession from '@/components/forms/oralB/CreateOralBSession';
import CreateGilletteSession from '@/components/forms/gillette/CreateGilletteSession';
import CreateLabsSession from '@/components/forms/labs/CreateLabsSession';
import CreateBraunSession from '@/components/forms/braun/CreateBraunSession';

export default {
  components: {
    CreateOralBSession,
    CreateLabsSession,
    CreateGilletteSession,
    CreateBraunSession
  },
  computed: {
    loading () {
      return this.$store.getters.loading;
    },
    loadingMessage () {
      return this.$store.getters.loadingStatusMessage;
    }
  },
  methods: {
    createSessionObj () {
      if (this.$store.state.brand === this.$store.state.brandConstants.opte) {
        return opteSessionInput();
      }
      // add additional brand logic here if they do not have a custom form
    },

    async addSession () {
      this.$store
        .dispatch('createSessionMutation', this.createSessionObj())
        .then(res => {
          if (res.data) {
            this.$store.dispatch('getSessionsQuery');
            this.selectedSession = null;
            this.formError = '';
          } else {
            this.formError = res.errors[0].message;
          }
        })
        .catch(() => {
          this.formError = 'Sorry - There was an error. Please try again.';
        });
    }
  }
};

const randomNumber = () => Math.floor(Math.random() * 10);

// add addition brand logic here

const opteSessionInput = () => ({
  session: {
    deviceId: '12345',
    deviceType: 'DREAMWORKS',
    consumerId: uuidv4(),
    sessionId: uuidv4(),
    abTimeoutGrace: randomNumber(),
    abTimeoutIdle: randomNumber(),
    abTimeoutShutdown: randomNumber(),
    abTimeoutSilence: randomNumber(),
    abandonLevel0: 0,
    abandonLevel1: 0,
    abandonLevel2: 0,
    abandonLevel3: 0,
    appOffsetX: 2,
    appOffsetY: -3,
    baseBootcount: 10,
    baseFirmwareBuildOptions: '0x001C1812',
    baseFirmwareFlag: 'EVT',
    baseFirmwareVersion: '0x05020300',
    baseHardwareModel: 'GEN2A',
    baseHardwareVersion: '0x000000B2',
    baseUptime: 111000,
    cameraOffsetHorz: -3,
    cameraOffsetVert: 7,
    cameraOn: 1,
    cameraPerformance: 100,
    cameraRotation: 3,
    cameraRotationalAngle: 0.2618,
    cameraX: randomNumber(),
    cameraY: randomNumber(),
    cartAbsent: 0,
    cartChange: 0,
    cartDropsPrint: 0,
    cartDropsPurge: 0,
    cartTally: 2,
    cartTimeOnSkin: 300,
    cleaning: 0,
    cradleEvents: 3,
    cradleIn: 1,
    cradleOut: 1,
    displayInversionState: 'NORMAL',
    dropsPerClick: 346,
    heaterTimeout: 0.65,
    idleTimeout: 120,
    imageHeight: 14,
    imageWidth: 126,
    lidClose: 1,
    lidEvents: 3,
    lidOpen: 1,
    nozzlePrefireDelta: 1.2,
    nozzlePrefireWidth: 0.7,
    nozzlePulseDelta: 1.2,
    nozzlePulseWidth: 1.7,
    puckAbsent: 0,
    puckEvents: 0,
    puckPresent: 0,
    regVoltageMax: 0.0,
    regVoltageMin: 0.0,
    rollerWindowHeight: 120,
    rollerWindowOffsetX: 59,
    rollerWindowOffsetY: 16,
    rollerWindowWidth: 8,
    rssi: -70,
    sessionDuration: 180,
    sessionStartTime: '2019-10-09T19:41:03+00:00',
    shade: 1,
    skinImageHeight: 18,
    skinRowStart: 1,
    tempCutoffHigh: 45,
    tempCutoffLow: 0,
    tempShutdownHigh: 60,
    tempShutdownLow: -20,
    thermalEvent: 1,
    tipAbsent: 0,
    tipEvents: 0,
    tipPresent: 0,
    wandAbsent: 0,
    wandBootcount: 0,
    wandEvents: 0,
    wandFirmwareBuildDate: '2019-06-24T19:41:03+00:00',
    wandFirmwareBuildOptions: '0x001C1812',
    wandFirmwareFlag: 'EVT',
    wandFirmwareVersion: '0x05020300',
    wandHardwareModel: 'GEN2A',
    wandHardwareVersion: '0x000000B2',
    wandPresent: 0,
    wandUptime: 0
  }
});
</script>
