<template>
  <div class="text-left">
    <button @click="toggleSessionModal" data-cy="create-session">
      <span>Add a session</span>
    </button>
    <SimpleModal class="text-center" v-if="toggleModal" @close="toggleModal = false">
      <div class="flex flex-wrap mb-14 justify-start">
        <div class="w-1/2">
          <p class="text-xs text-black">Device</p>
          <select v-model="deviceId" placeholder="12345">
            <option
              v-for="device in this.$store.state.devices"
              v-bind:key="device.deviceId"
              v-bind:value="device.deviceId"
            >{{ device.deviceType }} - {{ device.deviceId }}</option>
          </select>
        </div>
        <div class="w-1/2">
          <BaseInput
            class="text-theme-darkest mr-4"
            label="Session Type"
            placeholder="session type"
            v-model="sessionType"
            data-cy="sessionType"
            type="text"
          />
        </div>
        <div class="w-1/2">
          <p class="text-xs text-black">Received Time</p>
          <div
            class="flex justify-start mb-4 p-3 pl-6 w-full"
            style="border-bottom: 1px solid #949494"
          >
            <datetime
              type="datetime"
              v-model="receivedTime"
              placeholder="Select date"
              value-zone="UTC"
              zone="local"
              :phrases="{ ok: 'Continue', cancel: 'Exit' }"
              :hour-step="1"
              :minute-step="1"
              :week-start="7"
              use12-hour
              auto
            ></datetime>
          </div>
        </div>
        <div class="w-1/2">
          <p class="text-xs text-black">Session Start Time</p>
          <div
            class="flex justify-start mb-4 p-3 pl-6 w-full"
            style="border-bottom: 1px solid #949494"
          >
            <datetime
              type="datetime"
              v-model="sessionStartTime"
              placeholder="Select date"
              value-zone="UTC"
              zone="local"
              :phrases="{ ok: 'Continue', cancel: 'Exit' }"
              :hour-step="1"
              :minute-step="1"
              :week-start="7"
              use12-hour
              auto
            ></datetime>
          </div>
        </div>
        <div class="w-1/2">
          <BaseInput
            class="text-theme-darkest mr-4"
            label="Message"
            placeholder='{"json":"required"}'
            v-model="message"
            data-cy="message"
            type="text"
          />
        </div>
      </div>

      <button :disabled="loading" @click="addSession" data-cy="submit-create-session">
        <span v-if="loading">{{ loadingMessage }}</span>
        <span v-else>Create a session</span>
      </button>
      <p class="error m-2" v-if="formError">{{ formError }}</p>
    </SimpleModal>
  </div>
</template>

<script>
import { uuidv4 } from '@/util/uuid';
import SimpleModal from '@/components/UI/SimpleModal.vue';
import { Datetime } from 'vue-datetime';

export default {
  data () {
    return {
      toggleModal: false,
      formError: '',
      deviceId: this.$store.getters.devices.length
        ? this.$store.getters.devices[0].deviceId
        : '1234',
      deviceType: this.$store.getters.devices.length
        ? this.$store.getters.devices[0].deviceType
        : 'STAND',
      receivedTime: new Date().toISOString(),
      sessionStartTime: new Date().toISOString(),
      sessionType: null,
      message: null
    };
  },
  components: {
    SimpleModal,
    datetime: Datetime
  },
  computed: {
    loading () {
      return this.$store.getters.loading;
    },
    loadingMessage () {
      return this.$store.getters.loadingStatusMessage;
    },
    thingName () {
      return this.deviceType + '_' + this.deviceId;
    }
  },
  methods: {
    toggleSessionModal () {
      this.toggleModal = !this.toggleModal;
    },
    resetForm () {
      // users can customize sessionId? this.sessionId = uuidv4();
      this.deviceId = this.$store.state.devices.length
        ? this.$store.state.devices[0].deviceId
        : '12345';
      this.receivedTime = new Date().toISOString();
      this.sessionStartTime = new Date().toISOString();
      this.message = null;
      this.sessionType = null;
      this.deviceType = this.$store.getters.devices.length
        ? this.$store.getters.devices[0].deviceType
        : 'STAND';
    },
    createSessionObj () {
      return {
        input: {
          // required fields
          sessionId: uuidv4(),
          deviceId: this.deviceId,
          deviceType: this.deviceType,
          receivedTime: this.receivedTime.split('.')[0] + '+00:00',
          sessionStartTime: this.sessionStartTime.split('.')[0] + '+00:00',
          thingName: this.thingName,
          message: this.message,
          sessionType: this.sessionType
        }
      };
    },

    async addSession () {
      this.$store
        .dispatch('createSessionMutation', this.createSessionObj())
        .then(res => {
          if (res.data) {
            this.$store.dispatch('getSessionsQuery');
            this.toggleModal = false;
            this.formError = '';
            this.resetForm();
          } else {
            this.formError = res.errors[0].message;
          }
        })
        .catch(() => {
          this.formError = 'Sorry - There was an error. Please try again.';
        });
    }
  }
};
</script>
